import { generateClassName } from "@smoothincorg/smooth-ui"
import React from "react"
import { useMessageForm } from "../hooks/useMessageForm"
import { ResultWithPageContent } from "../api/get-template-messages"
import styled from "styled-components"
import { BiSend } from "react-icons/bi"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import { SMOOTH_SMOOTHER_API_URL } from "src/lib/constants"
type V2TemplateMessagesType =
  | {
      type: "flex"
      altText: "チャットお部屋提案の希望条件を登録してください"
      contents: {
        type: "bubble"
        hero: {
          type: "image"
          url: string
          size: "full"
          aspectRatio: "10:10"
          aspectMode: "cover"
          action: {
            type: "uri"
            label: "action"
            uri: "https://liff.line.me/1653845526-0eGJGLyn/search-houses"
          }
        }
      }
    }
  | {
      type: "text"
      text: string
    }
  | {
      type: "imagemap"
      baseUrl: string
      altText: string
    }

type Props = {
  className?: string
  templateMessages: ResultWithPageContent[]
  userId: number
} & Pick<ReturnType<typeof useMessageForm>, "appendMessage">

const { getRootClassName } = generateClassName("V2TemplateMessages")

const isCodeBlock = (
  block: Record<string, unknown>
): block is {
  code: {
    caption: []
    rich_text: [
      {
        type: "text"
        text: {
          content: string
          link: null
        }
        annotations: {
          bold: false
          italic: false
          strikethrough: false
          underline: false
          code: false
          color: "default"
        }
        plain_text: string
        href: null
      }
    ]
    language: "json"
  }
} => {
  return Object.keys(block).includes("code")
}

const MessageWrapper = styled.div`
  margin: 2rem 0;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.07);
`

const Component: React.FC<Props> = (props) => {
  const { postWithToken } = useSmoothApi()

  return (
    <div className={getRootClassName(props)}>
      {props.templateMessages.map((templateMessage) => {
        const messages: V2TemplateMessagesType[] =
          templateMessage.blocks.results
            .map((block) => {
              if (isCodeBlock(block)) {
                return JSON.parse(block["code"].rich_text[0].text.content)
              }
            })
            .filter(Boolean) ?? []

        return (
          <div key={templateMessage.id} className={`${props.className}__item`}>
            <span>
              <h3>{templateMessage.properties["名前"]?.title?.[0]?.text?.content}</h3>
              <span>
                {messages.map((message) => {
                  if (message.type === "text") {
                    return <MessageWrapper key={message.text}>{message.text}</MessageWrapper>
                  } else if (message.type === "imagemap") {
                    return (
                      <MessageWrapper key={`${message.baseUrl}1040`}>
                        <img
                          src={`${message.baseUrl}1040`}
                          style={{
                            width: "100%",
                            maxWidth: 200,
                            height: "auto%",
                          }}
                          alt={message.altText}
                        />
                      </MessageWrapper>
                    )
                  }
                  return (
                    <MessageWrapper key={message.contents.hero.url}>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        src={message.contents.hero.url}
                        style={{
                          width: "100%",
                          maxWidth: 200,
                          height: "auto%",
                        }}
                        alt={message.altText}
                      />
                    </MessageWrapper>
                  )
                })}
              </span>
            </span>
            <button
              type="button"
              onClick={async () => {
                const confirmed = confirm("送信しますか？")

                if (confirmed) {
                  try {
                    const res = await postWithToken(`${SMOOTH_SMOOTHER_API_URL}/line_message`, {
                      messages,
                      user_ids: [props.userId],
                    })

                    if (res.status !== 200) {
                      alert("送信に失敗しました")
                    }

                    if (res.data?.["message"]) {
                      alert(res?.data["message"])
                      window.location.reload()
                    }
                  } catch (e) {
                    alert("送信に失敗しました")
                  }
                }
              }}
            >
              <BiSend
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </button>
          </div>
        )
      })}
    </div>
  )
}

const StyledComponent = styled(Component)`
  height: 400px;
  overflow-y: scroll;

  &__item {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  }
`

export const V2TemplateMessages = StyledComponent
