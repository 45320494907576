import React, { useState } from "react"
import { DefaultLayout } from "src/components/Layout"
import styled from "styled-components"
import { Auth } from "src/features/auth"
import { LineChat } from "src/features/chat/components/LineChat"
import { useLineChatState } from "src/features/chat/hooks/useLineChatState"
import { Result } from "src/lib/notion/api-types"
import { GetStaticProps } from "next"
import {
  getTemplateMessages,
  getV2TemplateMessages,
  ResultWithPageContent,
} from "src/features/chat/api/get-template-messages"
import { useRouter } from "next/router"
import Head from "next/head"

type ModalState = {
  content: React.ReactNode
}

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 4;
`

export const ModalContext =
  React.createContext<[ModalState, React.Dispatch<React.SetStateAction<ModalState>>]>(null)

type Props = {
  templateMessages?: Result[] | Error
  v2TemplateMessages?: ResultWithPageContent[] | Error
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const templateMessages = await getTemplateMessages()
  const v2TemplateMessages = await getV2TemplateMessages()

  return {
    props: {
      templateMessages,
      v2TemplateMessages,
    },
    revalidate: 20,
  }
}

const LineChatPage: React.VFC<Props> = (props) => {
  const [modal, setModal] = useState<ModalState>()
  const router = useRouter()
  const { lineChatState, setLineChatState } = useLineChatState()

  React.useEffect(() => {
    const passedUserId = Number(router.query.user_id)
    const selectedInChatUserId = Number(router.query.selected_in_chat_user_id) // 外部からではなく、チャット機能内部で選択されたユーザーID(リロードに対応)

    if (!lineChatState.selectedUserId) {
      // まだチャットルームが選択されていない場合、 user_idかselected_in_chat_user_idがクエリパラメータで指定された場合はそのパラメータを、指定されていない場合は先頭のチャットルームをstateに設定
      if (passedUserId) {
        setLineChatState((state) => ({
          ...state,
          passedUserId,
          selectedUserId: passedUserId,
        }))
      } else if (selectedInChatUserId) {
        // selectedInChatUserIdはpassedUserIdにはセットしない(=チャットルームリストの先頭には表示しない)
        setLineChatState((state) => ({
          ...state,
          selectedUserId: selectedInChatUserId,
        }))
      } else if (!router.query.chat_room_id && lineChatState.talkRooms.length > 0) {
        // 以前のパラメータであるchat_room_idが来た場合はURLがリプレイスされるので、リプレイス後を考えてselectedUserIdを設定しない
        setLineChatState((state) => ({
          ...state,
          selectedUserId: lineChatState.talkRooms[0]?.userId,
        }))
      }
    }
    if (!passedUserId && router.query.chat_room_id && lineChatState.talkRooms.length > 0) {
      // 正規のパラメータ名はuser_idだが、以前はchat_room_idだったため、しばらくのあいだはchat_room_idでアクセスが来た場合はuser_idに変更
      const { chat_room_id, ...params } = router.query // chat_room_idはパラメータから除外
      router.replace({
        query: {
          ...params,
          user_id: chat_room_id,
        },
      })
    }
  }, [router, lineChatState, setLineChatState])

  return (
    <ModalContext.Provider value={[modal, setModal]}>
      {modal?.content && (
        <Modal
          onClick={() => {
            setModal((c) => ({
              ...c,
              content: null,
            }))
          }}
        >
          {modal?.content}
        </Modal>
      )}
      <Head>
        <title>チャット | for CS</title>
      </Head>
      <DefaultLayout>
        <Auth>
          <LineChat
            templateMessages={props.templateMessages}
            v2TemplateMessages={props.v2TemplateMessages}
          />
        </Auth>
      </DefaultLayout>
    </ModalContext.Provider>
  )
}

export default LineChatPage
