import anchorme from "anchorme"
import { ModalContext } from "src/pages/admin/chat"
import React from "react"
import { useContext } from "react"
import styled from "styled-components"
import { filterXSS } from "xss"
import { MessageType } from "./Message"
import { color, font, generateClassName } from "@smoothincorg/smooth-ui"
import { User } from "../types"
import { SMOOTH_LINE_API_URL } from "src/lib/constants"
import { useSmoothApi } from "src/hooks/useSmoothApi"
import Head from "next/head"
import useSWR from "swr"

//cs.next.smooth.jp/admin/chat?selected_talk_room_id=116045

type Props = {
  user: User
  message: MessageType
  className?: string
}

const ModalImage = styled.img`
  display: block;
  height: 80%;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const { getRootClassName } = generateClassName("MessageContent")

const ImageMessage = (props: { message: MessageType; user: User }) => {
  const [_, setModal] = useContext(ModalContext)
  const { getWithToken } = useSmoothApi()
  const message = props.message

  const imagesSWR = useSWR(`${props.message.id}`, async () => {
    const getBuffer = async (url: string) => {
      const { data } = await getWithToken(url)
      return data.image_url
    }

    if (!message?.content) {
      return null
    }

    const originalImageUrl =
      SMOOTH_LINE_API_URL +
      `/base_api/smoother/chat_rooms/${message.chatRoomId}/images/${message.id}/show_original_image`

    const previewImageUrl =
      SMOOTH_LINE_API_URL +
      `/base_api/smoother/chat_rooms/${message.chatRoomId}/images/${message.id}/show_preview_image`

    const [originalImage, previewImage]: [string | null, string | null] = await Promise.all([
      getBuffer(originalImageUrl).catch(() => null),
      getBuffer(previewImageUrl).catch(() => null),
    ])

    return {
      originalImage,
      previewImage,
    }
  })

  const originalImageUrl = imagesSWR.data?.originalImage
  const previewImageUrl = imagesSWR.data?.previewImage

  if (!previewImageUrl) {
    return <div className={` message-content`}>{`[画像取得エラー]`}</div>
  }

  return (
    <a
      href={originalImageUrl}
      target="_blank"
      className={`message-content image`}
      rel="noreferrer"
      onClick={(e) => {
        e.preventDefault()
        if (originalImageUrl) {
          setModal((s) => ({
            ...s,
            content: <ModalImage src={originalImageUrl} />,
          }))
          return
        }

        alert("画像の取得に失敗しました")
      }}
    >
      <Head>
        <link key={originalImageUrl} rel="preload" href={originalImageUrl} as={`image`} />
      </Head>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={previewImageUrl} alt="" />
    </a>
  )
}

const isObject = (value: any) => {
  return value && typeof value === "object" && value.constructor === Object
}

export const Component: React.FC<Props> = (props) => {
  const message = props.message

  if (
    message?.content?.type === "text" &&
    isObject(message.content.text) &&
    typeof message.content.text !== "string"
  ) {
    if (message.content?.text?.contents?.hero?.url) {
      return (
        <div
          className={`${getRootClassName(props)} message-content`}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "transparent",
            width: 300,
            padding: 0,
          }}
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={message.content?.text?.contents?.hero?.url}
            alt=""
            style={{ width: "100%", maxWidth: 300 }}
          />
        </div>
      )
    }

    return (
      <div className={`${getRootClassName(props)} message-content`}>
        <pre>{JSON.stringify(message.content.text, null, 3)}</pre>
      </div>
    )
  }

  if (message?.content?.type === "video") {
    return <div className="">{`[VIDEO] ID:${message.content?.id}`}</div>
  }

  if (!message?.content) {
    return (
      <div className={`${getRootClassName(props)} message-content`}>
        <div className="">{`[メッセージ取得エラー]`}</div>
      </div>
    )
  }

  if (message?.content?.type === "image") {
    return <ImageMessage message={message} user={props.user} />
  }

  if (message?.content?.type === "sticker") {
    const stickerImageUrl = `https://stickershop.line-scdn.net/stickershop/v1/sticker/${message.content.stickerId}/android/sticker.png`
    return (
      <div className={`${getRootClassName(props)} message-content sticker`}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={stickerImageUrl} alt="" />
      </div>
    )
  }

  const text = anchorme({
    input: `${message?.content?.text}`,
    options: {
      attributes: () => {
        const attributes = {
          target: "_blank",
          rel: "noopener noreferrer",
        }
        return attributes
      },
    },
  })

  return (
    <div
      className={`${getRootClassName(props)} message-content`}
      dangerouslySetInnerHTML={{
        __html: filterXSS(text, {
          whiteList: {
            a: ["href", "title", "target", "rel"],
          },
        }),
      }}
    />
  )
}

const StyledComponent = styled(Component)`
  ${font(16)};
  overflow-wrap: break-word;
  white-space: pre-wrap;
  line-height: 150%;
  position: relative;
  z-index: 1;

  > a {
    display: inline;
    text-decoration: underline;
    color: ${color.darkGray};
  }
`

export const MessageContent = StyledComponent
