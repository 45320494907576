import React, { useEffect, useMemo, useState } from "react"

// Line Chat Relative Components
import { TalkRoom, DisplayedSections, User } from "src/features/chat/types"
import { TalkHeader, TalkHeaderProps } from "./TalkHeader"
import { MessagesContainer } from "src/features/chat/containers/MessagesContainer"
import styled from "styled-components"
import { color, generateClassName } from "@smoothincorg/smooth-ui"
import { useLineChatState } from "../hooks/useLineChatState"
import { useChatRoomView } from "../hooks/useChatRoomView"
import { useSelectedTalkRoom } from "../hooks/useSelectedTalkRoom"
import { useGetBaseData } from "../hooks/useGetBaseData"
import { MessageForm } from "./MessageForm"
import { useRecoilState } from "recoil"
import { lineChatMessagesState } from "src/store/lineChatState"
import { Result } from "src/lib/notion/api-types"
import { HEADER_HEIGHT } from "src/components/Header/Header.css"
import { PokeLoading } from "src/components/Loading"
import { ResultWithPageContent } from "../api/get-template-messages"

type Props = TalkHeaderProps & {
  talkRoom: TalkRoom
  user: User
  displayedSections: DisplayedSections
  className?: string
  templateMessagesAvailable: boolean
} & ContainerProps

const { getRootClassName, className } = generateClassName("TalkSection")

const Component: React.VFC<Props> = (props) => {
  const talkRoom = props.talkRoom
  const [formHeight, setFormHeight] = useState(100)

  const addFormHeight = () => {
    const form = document.getElementById("messages_form")
    if (form instanceof HTMLDivElement) {
      setFormHeight(form.clientHeight)
    }
  }

  useEffect(() => {
    window.addEventListener("click", addFormHeight)
    return () => window.removeEventListener("click", addFormHeight)
  }, [])

  useEffect(() => {
    addFormHeight()
  }, [props.templateMessagesAvailable])

  if (!talkRoom) {
    return null
  }

  return (
    <div
      className={`${getRootClassName(props)} ${
        props.displayedSections["chatSection"] == false && "u-display-none"
      }`}
    >
      <TalkHeader
        // States
        talkRoom={talkRoom}
        user={props.user}
        // Functions
        openTalkRoomsSection={props.openTalkRoomsSection}
        openTalkRoomInfoSection={props.openTalkRoomInfoSection}
      />

      <div
        className={`${className}__messages`}
        style={{
          height: `calc(100vh - 56px - ${formHeight}px - ${HEADER_HEIGHT}px)`,
        }}
      >
        <MessagesContainer />
      </div>

      <MessageForm
        userId={props.user.id}
        templateMessages={props.templateMessages}
        v2TemplateMessages={props.v2TemplateMessages}
      />
    </div>
  )
}

export const StyledComponent = styled(Component)`
  position: relative;

  .${className}__messages {
    margin-top: 56px;
    height: calc();

    > .smooth-ui-Messages {
      min-height: 100%;
    }
  }

  .smooth-ui-TalkHeader {
    position: absolute;
    z-index: 2;
    background-color: ${color.white};
    width: 100%;
  }

  .smooth-ui-MessageForm {
    min-height: 100px;
  }
`

type ContainerProps = {
  templateMessages: Result[] | Error
  v2TemplateMessages: ResultWithPageContent[] | Error
}

export const TalkSection: React.VFC<ContainerProps> = (props) => {
  const { lineChatState, isValidating } = useLineChatState()
  const { openTalkRoomInfoSection, openTalkRoomsSection } = useChatRoomView()
  const { selectedTalkRoom } = useSelectedTalkRoom()
  const { getUser } = useGetBaseData()
  const [messages] = useRecoilState(lineChatMessagesState)

  const user = useMemo(() => {
    if (!selectedTalkRoom) return null
    return getUser(selectedTalkRoom?.userId)
  }, [getUser, selectedTalkRoom])

  const templateMessagesAvailable = useMemo(() => {
    if (props.templateMessages instanceof Error || !props.templateMessages?.length) return false

    if (messages?.length > 0) {
      return false
    }

    return true
  }, [props.templateMessages, messages?.length])

  if (!user || !selectedTalkRoom) {
    if (isValidating) return <PokeLoading />

    return (
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 56px)",
          width: "100%",
        }}
      >
        <span
          style={{
            fontWeight: "500",
          }}
        >
          {`トークルームを選択してください`}
        </span>
      </div>
    )
  }

  return (
    <StyledComponent
      // States
      talkRoom={selectedTalkRoom}
      displayedSections={lineChatState.displayedSections}
      // Functions
      openTalkRoomsSection={openTalkRoomsSection}
      openTalkRoomInfoSection={openTalkRoomInfoSection}
      user={user}
      templateMessagesAvailable={templateMessagesAvailable}
      templateMessages={props.templateMessages}
      v2TemplateMessages={props.v2TemplateMessages}
    />
  )
}
